import React from 'react';
import classNames from 'classnames';
import uniqueId from 'uniqid';

import Button from '../Button';
import Link from '../Link';
import Image from '../Image';
import Reveal from '../Reveal';

const nsBase = 'component';
const ns = `${ nsBase }-two-column-img-overlap`;

const TwoColumnImgOverlap = (props) => {
	const rootClassnames = classNames({
		[`${ ns }`]: true
	});

	const {
		description,
		items,
		cta,
		images
	} = props;

	const {
		background: bgImage,
		foreground: fgImage
	} = images;

	const {
		label,
		url
	} = cta;

	return (
		<div className={rootClassnames}>
			<div className={'container-fluid'}>
				<div className={`${ ns }__col`}>
					<div className={`${ ns }__image-content`}>
						<div className={`${ ns }__bg-image`}>
							<Reveal>
								<Image {...bgImage} />
							</Reveal>
						</div>
						{fgImage && (
							<div className={`${ ns }__fg-image`}>
								<Reveal>
									<Image {...fgImage} />
								</Reveal>
							</div>
						)}
					</div>
				</div>
				<div className={`${ ns }__col`}>
					<Reveal>
						<div className={`${ ns }__text-content`}>
							{description && (
								<Reveal>
									<h3>{description}</h3>
								</Reveal>
							)}
							{items.length && (
								<Reveal>
									<ul>
										{items.map((item) => {
											return (
												<li key={uniqueId('envoy')}>
													{item.url ? (
														<Link
															variant={'underline'}
															to={item.url}
														>
															{item.label}
														</Link>
													) : (
														item.label
													)}
												</li>
											);
										})}
									</ul>
								</Reveal>
							)}
							{label && url && (
								<Reveal>
									<Button href={url}>{label}</Button>
								</Reveal>
							)}
						</div>
					</Reveal>
				</div>
			</div>
		</div>
	);
};

export default TwoColumnImgOverlap;
