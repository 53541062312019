import React from 'react';
import classNames from 'classnames';

import Button from '../Button';
import Reveal from '../Reveal';

const nsBase = 'component';
const ns = `${ nsBase }-text-block`;

const TextBlock = (props) => {
	const rootClassnames = classNames({
		[`${ ns }`]: true
	});

	const {
		heading,
		description,
		cta
	} = props;

	return (
		<div className={rootClassnames}>
			<div className={'container-fluid'}>
				<Reveal>
					<div className={`${ ns }__text-content`}>
						{heading && (
							<h2>{heading}</h2>
						)}
						{description && (
							<p>{description}</p>
						)}
						{cta && cta.label && cta.url && (
							<Button href={cta.url} variant={'white'}>{cta.label}</Button>
						)}
					</div>
				</Reveal>
			</div>
		</div>
	);
};

export default TextBlock;
