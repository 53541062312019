import React from 'react';
import classNames from 'classnames';
import uniqueId from 'uniqid';

import Reveal from '../Reveal';

const nsBase = 'component';
const ns = `${ nsBase }-narrow-txt-content`;

const NarrowTxtContent = (props) => {
	const rootClassnames = classNames({
		[`${ ns }`]: true
	});

	const {
		heading,
		items
	} = props;

	return (
		<div className={rootClassnames}>
			<div className={'container-fluid'}>
				<div className={`${ ns }__content`}>
					<div className={`${ ns }__content-left`}>
						{heading && (
							<Reveal>
								<h2>{heading}</h2>
							</Reveal>
						)}
					</div>
					<div className={`${ ns }__content-right`}>
						{items.length && (
							<ul>
								{items.map((item) => {
									const {
										heading: itemHeading,
										description: itemDescription
									} = item;

									return (
										<li key={uniqueId('envoy')}>
											<Reveal>
												{itemHeading && (
													<h3>{itemHeading}</h3>
												)}
												{itemDescription && (
													<p>{itemDescription}</p>
												)}
											</Reveal>
										</li>
									);
								})}
							</ul>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default NarrowTxtContent;
