import React from 'react';
import classNames from 'classnames';
import uniqueId from 'uniqid';

import Button from '../Button';
import Image from '../Image';
import Reveal from '../Reveal';

const nsBase = 'component';
const ns = `${ nsBase }-offset-image-columns`;

const OffsetImageColumns = (props) => {
	const rootClassnames = classNames({
		[`${ ns }`]: true
	});

	const {
		heading,
		description,
		cta: {
			label,
			url
		},
		items
	} = props;

	return (
		<div className={rootClassnames}>
			<div className={'container-fluid'}>
				<Reveal>
					<div className={`${ ns }__heading`}>
						{heading && (
							<h2>{heading}</h2>
						)}
						{description && (
							<p>{description}</p>
						)}
					</div>
				</Reveal>
				{items.length && (
					<ul className={`${ ns }__cols`}>
						{items.map((item) => {
							const {
								title: itemTitle,
								description: itemDesc,
								image: img
							} = item;

							return (
								<li
									key={uniqueId('envoy')}
									className={`${ ns }__col`}
								>
									<Reveal>
										<Image {...img} />
										<div className={`${ ns }__col-text`}>
											{itemTitle && (
												<h4>{itemTitle}</h4>
											)}
											{itemDesc && (
												<p>{itemDesc}</p>
											)}
										</div>
									</Reveal>
								</li>
							);
						})}

					</ul>
				)}
				{label && url && (
					<div className={`${ ns }__cta`}>
						<Reveal>
							<Button href={url} variant={`white`}>{label}</Button>
						</Reveal>
					</div>
				)}
			</div>
		</div>
	);
};

export default OffsetImageColumns;
