import React from 'react';
import { graphql } from 'gatsby';
import classNames from 'classnames';

import SEO from '../components/SEO';
import Hero from '../components/Hero';
import NarrowTxtContent from '../components/NarrowTxtContent';
import OffsetImageColumns from '../components/OffsetImageColumns';
import TwoColumnImgOverlap from '../components/TwoColumnImgOverlap';
import TextBlock from '../components/TextBlock';

const nsBase = 'page';
const ns = `${ nsBase }-home`;

const IndexPage = ({ data }) => {
	const rootClassnames = classNames({
		[`${ nsBase } ${ ns }`]: true
	});

	const {
		homeJson: {
			hero,
			textBlock,
			narrowTxtContent,
			offsetImageColumns,
			twoColumnImgOverlap
		}
	} = data;

	return (
		<div className={rootClassnames}>
			<SEO page={'home'} />
			<Hero
				heading={hero.heading}
				subcopy={hero.subcopy}
				description={hero.description}
				cta={hero.cta}
			/>
			<NarrowTxtContent
				heading={narrowTxtContent.heading}
				items={narrowTxtContent.items}
			/>
			<OffsetImageColumns
				heading={offsetImageColumns.heading}
				description={offsetImageColumns.description}
				cta={offsetImageColumns.cta}
				items={offsetImageColumns.items}
			/>
			<TextBlock
				heading={textBlock.heading}
				description={textBlock.description}
				cta={textBlock.cta}
			/>
			<TwoColumnImgOverlap
				description={twoColumnImgOverlap.description}
				items={twoColumnImgOverlap.items}
				cta={twoColumnImgOverlap.cta}
				images={twoColumnImgOverlap.images}
			/>
		</div>
	);
};

export default IndexPage;

export const query = graphql`
  query GET_PAGE_HOME {
		homeJson {
			hero {
				heading,
				subcopy,
				description,
				cta {
					label,
					url
				}
			},
			textBlock {
				heading,
				description,
				cta {
					label,
					url
				}
			},
			narrowTxtContent {
				heading,
				items {
					heading,
					description
				}
			}
			offsetImageColumns {
				heading,
				description,
				cta {
					label,
					url
				},
				items {
					image {
						alt_text,
						main_image,
						laptop_image
					},
					title,
					description
				}
			}
			twoColumnImgOverlap {
				description,
				items {
					label,
					url
				},
				cta {
					label,
					url
				},
				images {
					background {
						alt_text,
						main_image,
						laptop_image
					}
				}
			}
		}
	}
`;
